<template>
    <div class="d-flex justify-content-end btn-group-table align-items-center">
        <a role="button" @click="showModalReminder" class="text-decoration mr-2">
            {{ remind ? remind.remind_at : 'Напомнить'}}
        </a>
    </div>
</template>

<script>
import ModalReminderComponent from "../../../modal/Proposol/ModalReminderComponent";

export default {
    name: "ReminderComponent",
    props: ['createUrl', 'editUrl', 'remindUrl', 'redirectUrl'],
    data () {
        return {
            user: null,
            remind: null,
        }
    },
    mounted() {
        this.getRemind()
    },
    methods: {
        showModalReminder() {
            this.$modal.show(ModalReminderComponent,
                {
                    createUrl: this.createUrl,
                    editUrl: this.editUrl,
                    reminder: this.remind,
                    remindUrl: this.remindUrl,
                    redirectUrl: this.redirectUrl,
                    callMethodInParent: this.callMethodInParent
                },
                {
                    height: 'auto',
                    width: '400px'
                })
        },

        callMethodInParent() {
            this.$forceUpdate();
        },

        getRemind() {
            axios.get(this.remindUrl).then(response => {
                if(response.data) {
                    this.remind = response.data.data;
                }
            })
        },
    }
}
</script>
