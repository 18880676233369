<template>
    <select v-bind:name="name" class="form-control" v-bind:multiple="multiple" v-bind:required="required"></select>
</template>

<script>
export default {
    props: {
        name: '',
        options: {
            Object
        },
        value: null,
        multiple: {
            Boolean,
            default: false

        },
        required: {
            Boolean,
            default: false

        },
    },
    data() {
        return {
            select2data: []
        }
    },
    mounted() {
        this.formatOptions()
        let vm = this
        let select = $(this.$el)
        select
            .select2({
                placeholder: 'Выберите вариант',
                theme: 'bootstrap-5',
                minimumResultsForSearch: Infinity,
                data: this.select2data
            })
            .on('change', function () {
                vm.$emit('input', select.val())
            })
        select.val(this.value).trigger('change')
    },
    methods: {
        formatOptions() {
            this.select2data.push({ id: '', text: 'Select' })
            for (let key in this.options) {
                this.select2data.push({ id: key, text: this.options[key] })
            }
        }
    },
    destroyed: function () {
        $(this.$el).off().select2('destroy')
    }
}
</script>
