<template>
    <div class="d-flex justify-content-end btn-group-table align-items-center">
        <button @click="showModalCancel" class="btn btn-secondary mx-2">
            Отменить
        </button>
    </div>
</template>

<script>
import ModalShowCancelComponent from "../../../modal/Proposol/Tenant/ModalShowCancelComponent";

export default {
    name: "ShowCancelComponent",
    props: ['cancelUrl', 'redirectUrl'],
    data () {
        return {
            user: null
        }
    },
    methods: {
        showModalCancel() {
            this.$modal.show(ModalShowCancelComponent,
                {
                    cancelUrl: this.cancelUrl,
                    redirectUrl: this.redirectUrl
                },
                {
                    height: 'auto',
                    width: '400px'
                })
        },
    }
}
</script>
