<template>
    <div>
        <div class="modal-header border-0 d-flex align-items-start">
            <div class="d-flex">
                <h5 class="name modal-title">Начать выполнение заявки?</h5>
            </div>
            <button type="button" class="bg-transparent btn-sm btn" @click="$emit('close')">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">' +
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8183 5.18264C14.8765 5.2407 14.9227 5.30967 14.9542 5.3856C14.9857 5.46153 15.0019 5.54293 15.0019 5.62514C15.0019 5.70735 14.9857 5.78875 14.9542 5.86468C14.9227 5.94061 14.8765 6.00958 14.8183 6.06764L6.06829 14.8176C5.95093 14.935 5.79176 15.0009 5.62579 15.0009C5.45982 15.0009 5.30065 14.935 5.18329 14.8176C5.06593 14.7003 5 14.5411 5 14.3751C5 14.2092 5.06593 14.05 5.18329 13.9326L13.9333 5.18264C13.9913 5.12444 14.0603 5.07826 14.1362 5.04675C14.2122 5.01524 14.2936 4.99902 14.3758 4.99902C14.458 4.99902 14.5394 5.01524 14.6153 5.04675C14.6913 5.07826 14.7602 5.12444 14.8183 5.18264Z" fill="#1D1D25"/>' +
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.18362 5.18264C5.12541 5.2407 5.07923 5.30967 5.04773 5.3856C5.01622 5.46153 5 5.54293 5 5.62514C5 5.70735 5.01622 5.78875 5.04773 5.86468C5.07923 5.94061 5.12541 6.00958 5.18362 6.06764L13.9336 14.8176C14.051 14.935 14.2101 15.0009 14.3761 15.0009C14.5421 15.0009 14.7013 14.935 14.8186 14.8176C14.936 14.7003 15.0019 14.5411 15.0019 14.3751C15.0019 14.2092 14.936 14.05 14.8186 13.9326L6.06862 5.18264C6.01056 5.12444 5.94159 5.07826 5.86566 5.04675C5.78973 5.01524 5.70833 4.99902 5.62612 4.99902C5.54391 4.99902 5.46251 5.01524 5.38658 5.04675C5.31064 5.07826 5.24167 5.12444 5.18362 5.18264Z" fill="#1D1D25"/>' +
                </svg>
            </button>
        </div>
        <form v-model="form" @submit.prevent="postStart" method="post" >
            <div class="modal-body pt-0">
                <div class="mb-3">
                    <div class="form-label required">Крайний срок выполнения заявки <span class="text-danger">*</span></div>
                    <input type="date" v-model="form.deadline" class="form-control w-100" id="deadline" name="deadline" required>
                </div>
            </div>
            <div class="modal-footer justify-content-start border-0 pt-3">
                <button type="submit" class="btn btn-danger">Начать</button>
            </div>
        </form>
    </div>
</template>

<script>

export default {
    name: "ModalDeadlineComponent",
    props: ['startUrl', 'redirectUrl'],
    data() {
        return {
            form: {
                deadline: null,
            },
        }
    },

    methods: {
        postStart(e) {
            e.preventDefault();

            axios.post(this.startUrl, this.form)
                .then((response) => {
                    toastr.success(response.data.message);
                    setTimeout(() => this.$emit('close'), 300)
                    this.resetForm()
                    setTimeout(() => document.location.reload(), 1000)
                })
                .catch(e => {
                    toastr.error(e.response.data.message);
                })
        },

        resetForm() {
            this.form.deadline = null
        }
    }
}
</script>
