require('./bootstrap');
// import Choices from "choices.js/public/assets/scripts/choices";
//
// let selectStateInputElements = document.querySelectorAll('.choices-default');
// let selectStateInputElement = document.querySelectorAll('.choices-single');
//
//
// selectStateInputElements.forEach(function (el) {
//     if(el) {
//         const choices = new Choices(el, {
//             classNames: {
//                 containerOuter: 'choices',
//                 containerInner: 'form-control',
//                 input: "border-0",
//                 inputCloned: 'choices__input--cloned',
//                 list: 'choices__list',
//                 listItems: 'choices__list--multiple',
//                 listSingle: 'choices__list--single',
//                 listDropdown: 'choices__list--dropdown',
//                 item: 'choices__item',
//                 itemSelectable: "choices__item--selectable",
//                 itemDisabled: 'choices__item--disabled',
//                 itemOption: 'choices__item--choice',
//                 group: 'choices__group',
//                 groupHeading : 'choices__heading',
//                 button: 'choices__button btn-select-delete',
//                 activeState: 'is-active',
//                 focusState: 'is-focused',
//                 openState: 'is-open',
//                 disabledState: 'is-disabled',
//                 highlightedState: 'is-highlighted',
//                 selectedState: 'is-selected',
//                 flippedState: 'is-flipped',
//             },
//
//             removeItemButton: true,
//             itemSelectText: '',
//             noChoicesText: 'Нет вариантов для выбора',
//             noResultsText: 'Нет вариантов для выбора',
//             placeholderValue: null,
//             searchPlaceholderValue: 'Выберите объекты',
//
//         });
//
//
//         if($(el).attr('id') && $(el).attr('id').toString() === 'facilities facilities-disabled') {
//             choices.disable()
//         }
//
//         if($(el).attr('disabled')) {
//             choices.disable()
//         }
//     }
//
//
// });
//
// selectStateInputElement.forEach(function (el) {
//     if(el) {
//         const choices = new Choices(el, {
//             removeItemButton: true,
//             itemSelectText: '',
//             noChoicesText: 'Нет вариантов для выбора',
//             noResultsText: 'Нет вариантов для выбора',
//             placeholder: false,
//             placeholderValue: 'Выберите объекты',
//             searchPlaceholderValue: 'Выберите объекты',
//
//             classNames: {
//                 containerOuter: 'choices',
//                 containerInner: 'form-control p-0',
//                 input: "border-0",
//                 inputCloned: 'choices__input--cloned',
//                 list: 'choices__list',
//                 listItems: 'choices__list--multiple',
//                 listSingle: 'choices__list--single p-0',
//                 listDropdown: 'choices__list--dropdown',
//                 item: 'choices__item p-3',
//                 itemSelectable: "choices__item--selectable",
//                 itemDisabled: 'choices__item--disabled',
//                 itemOption: 'choices__item--choice',
//                 group: 'choices__group',
//                 groupHeading : 'choices__heading',
//                 button: 'choices__button btn-select-delete',
//                 activeState: 'is-active',
//                 focusState: 'is-focused',
//                 openState: 'is-open',
//                 disabledState: 'is-disabled',
//                 highlightedState: 'is-highlighted',
//                 selectedState: 'is-selected',
//                 flippedState: 'is-flipped',
//             },
//
//         });
//     }
//
//
// });

// $(document).ready(function() {

jQuery('.table-responsive').on('show.bs.dropdown', function () {
    jQuery('.table-responsive').css( "min-height", "400px" );
});
//
jQuery('.table-responsive').on('hide.bs.dropdown', function () {
    jQuery('.table-responsive').css( "min-height", "none" );
})

jQuery(document).on("hide.bs.dropdown", "#table-dropdown", function(e) {
    jQuery('body').trigger('mousedown');
})

jQuery("#message-list").scrollTop(jQuery("#message-list").prop('scrollHeight'))

// Basic
$("select").select2({
    theme: "bootstrap-5",
    minimumResultsForSearch: Infinity,
});
