<template>
    <div class="d-flex justify-content-end btn-group-table align-items-center">
        <button @click="showModalReject" class="btn btn-danger mr-2">
            Начать выполнение заявки
        </button>
    </div>
</template>

<script>
import ModalDeadlineComponent from "../../../modal/Proposol/Staff/ModalDeadlineComponent";

export default {
    name: "ShowStartComponent",
    props: ['startUrl', 'redirectUrl'],
    data () {
        return {
            user: null
        }
    },
    methods: {
        showModalReject() {
            this.$modal.show(ModalDeadlineComponent,
                {
                    startUrl: this.startUrl,
                    redirectUrl: this.redirectUrl
                },
                {
                    height: 'auto',
                    width: '400px'
                })
        },
    }
}
</script>
