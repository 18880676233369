window._ = require('lodash');
import jQuery from 'jquery'
import select2 from 'select2';
import * as bootstrap from 'bootstrap';

window.bootstrap = bootstrap;

// window.Vue = require("vue").default;
import Vue from "vue";
import VModal from 'vue-js-modal';
import Toastr from 'toastr';

window.toastr = require('toastr');
toastr.options = {
    "closeButton": true,
    "newestOnTop": false,
}

try {
    // bootstrap = require('bootstrap');
    require('choices.js/public/assets/scripts/choices');
    let select2 = require('select2')
    require('select2/dist/js/i18n/ru')

    // require('./modal/modal')
} catch (e) {}

var mask = require('jquery-mask-plugin');
$.mask = mask;
window.mask = mask;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.CSRF = jQuery('meta[name="csrf-token"]').attr('content')


/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

if (jQuery("#app").length > 0) {
    Vue.use(VModal, {dynamicDefault: {draggable: false, resizable: false, height: 'auto'}})
    Object.defineProperty(Vue.prototype, '$toastr', { value: Toastr });

    Vue.component('short-show-staff-modal', require('./component/user/staff/ShortShowComponent.vue').default);
    Vue.component('short-show-tenant-modal', require('./component/user/tenant/ShortShowComponent.vue').default);
    Vue.component('show-proposal-reject-modal', require('./component/proposal/modal/ShowRejectComponent.vue').default);
    Vue.component('show-proposal-start-modal', require('./component/proposal/modal/ShowStartComponent.vue').default);
    Vue.component('show-proposal-remind-modal', require('./component/proposal/modal/ReminderComponent.vue').default);
    Vue.component('show-proposal-cancel-modal', require('./component/proposal/modal/ShowCancelComponent.vue').default);
    Vue.component('message-component', require('./component/proposal/message/MessageComponent.vue').default);


    const app = new Vue({
        el: '#app'
    });
}

var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl);
});
