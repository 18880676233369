<template>
    <div class="d-flex flex-column align-items-stretch flex-shrink-0 w-100 vh-100 mt-n5">
        <div id="message-list" class="scrollarea align-content-end mt-auto" ref="messagesContainer">
        <template v-for="message in messages">
            <div class="message-item" v-if="message.system" :key="message.id">
                <div v-if="message.files && message.files.length" class="border bg-white p-2 rounded wm-80 w-auto">
                    <template v-for="file in message.files">
                     <a target="_blank" :href="file.file_path" :title="file.filename_original">{{ file.filename }}</a><br >
                    </template>
                </div>
                <div v-else class="font-italic rounded wm-80 w-auto">{{ message.shot_user_name }} {{ message.message }}</div>
                <div class="font-weight-bold-700 text-size-12">{{ message.created_at }}</div>
            </div>

            <div class="message-item d-flex flex-column align-items-end " v-else-if="message.my_message" :key="message.id">
                <div class="font-italic font-weight-bold-700 text-size-12 text-secondary wm-90 w-auto mb-0">{{ message.shot_user_name }}</div>
                <div class="border bg-white p-2 rounded wm-80 w-auto">
                    {{ message.message }} <br v-if="message.message && message.files && message.files.length" />
                    <template v-if="message.files && message.files.length" v-for="file in message.files">
                        <a target="_blank" :href="file.file_path" :title="file.filename_original">{{ file.filename }}</a><br >
                    </template>
                </div>
                <div class="font-weight-bold-700 text-size-12">{{ message.created_at }}</div>
            </div>

            <div class="message-item d-flex flex-column align-items-start" v-else :key="message.id">
                <div class="font-italic font-weight-bold-700 text-size-12 text-secondary wm-90 w-auto mb-0">{{ message.shot_user_name }}</div>
                <div class="border bg-white p-2 rounded wm-80 w-auto">
                    {{ message.message }}
                    <template v-if="message.files && message.files.length" v-for="file in message.files">
                        <a target="_blank" :href="file.file_path" :title="file.filename_original">{{ file.filename }}</a><br >
                    </template>
                </div>
                <div class="font-weight-bold-700 text-size-12">{{ message.created_at }}</div>
            </div>
        </template>
        <div class="message-item" v-if="!messages.length">
            <div class="font-italic rounded w-100 text-center text-black-50">Сообщений пока нет</div>
        </div>
        </div>
        <div class="">
            <div class="message-create">
                <form  v-model="form" @submit.prevent="postMessage" method="POST">
                    <div class="input-group d-flex justify-content-end align-items-center">
                        <input type="text" v-model="form.message" name="message" value="" class="form-control rounded" placeholder="Введите сообщение" required>
                        <span class="position-absolute d-flex ">
                             <div class="input-group upload">
                                <label role="button" class="me-2" for="files">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.625 3.75C5.625 2.9212 5.95424 2.12634 6.54029 1.54029C7.12634 0.95424 7.9212 0.625 8.75 0.625C9.5788 0.625 10.3737 0.95424 10.9597 1.54029C11.5458 2.12634 11.875 2.9212 11.875 3.75V15C11.875 15.4973 11.6775 15.9742 11.3258 16.3258C10.9742 16.6775 10.4973 16.875 10 16.875C9.50272 16.875 9.02581 16.6775 8.67417 16.3258C8.32254 15.9742 8.125 15.4973 8.125 15V6.25C8.125 6.08424 8.19085 5.92527 8.30806 5.80806C8.42527 5.69085 8.58424 5.625 8.75 5.625C8.91576 5.625 9.07473 5.69085 9.19194 5.80806C9.30915 5.92527 9.375 6.08424 9.375 6.25V15C9.375 15.1658 9.44085 15.3247 9.55806 15.4419C9.67527 15.5592 9.83424 15.625 10 15.625C10.1658 15.625 10.3247 15.5592 10.4419 15.4419C10.5592 15.3247 10.625 15.1658 10.625 15V3.75C10.625 3.50377 10.5765 3.25995 10.4823 3.03247C10.388 2.80498 10.2499 2.59828 10.0758 2.42417C9.90172 2.25006 9.69502 2.11195 9.46753 2.01773C9.24005 1.9235 8.99623 1.875 8.75 1.875C8.50377 1.875 8.25995 1.9235 8.03247 2.01773C7.80498 2.11195 7.59828 2.25006 7.42417 2.42417C7.25006 2.59828 7.11195 2.80498 7.01773 3.03247C6.9235 3.25995 6.875 3.50377 6.875 3.75V15C6.875 15.8288 7.20424 16.6237 7.79029 17.2097C8.37634 17.7958 9.1712 18.125 10 18.125C10.8288 18.125 11.6237 17.7958 12.2097 17.2097C12.7958 16.6237 13.125 15.8288 13.125 15V6.25C13.125 6.08424 13.1908 5.92527 13.3081 5.80806C13.4253 5.69085 13.5842 5.625 13.75 5.625C13.9158 5.625 14.0747 5.69085 14.1919 5.80806C14.3092 5.92527 14.375 6.08424 14.375 6.25V15C14.375 16.1603 13.9141 17.2731 13.0936 18.0936C12.2731 18.9141 11.1603 19.375 10 19.375C8.83968 19.375 7.72688 18.9141 6.90641 18.0936C6.08594 17.2731 5.625 16.1603 5.625 15V3.75Z" fill="#797986"/>
                                    </svg>
                                </label>
                                <input id="files" class="form-control d-none" type="file" @change="uploadFile" multiple>
                            </div>
                            <button type="submit" class="btn btn-danger btn-sm me-2 px-1 py-0 rounded" :disabled="!!(this.await)" >Отправить</button>
                        </span>
                    </div>
                </form>
<!--                <div v-else class="text-center text-black-50 font-italic">-->
<!--                    Заявка уже не актуальна <br />-->
<!--                    Чат закрыт-->
<!--                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MessageComponent",
    props: {
        isClosed: Boolean,
        allUrl: String,
        postUrl: String
    },
    data () {
        return {
            form: {
                message: null,
                attach_files: []
            },
            messages: [],
            await: false
        }
    },
    mounted() {
        this.getMessages()

        this.$nextTick(function () {
            window.setInterval(() => {
                this.getMessages();
            },30000);
        })
    },
    updated() {
        this.$nextTick(() => this.scrollToEnd());
    },
    methods: {
        getMessages() {
            axios.get(this.allUrl).then(response => {
                this.messages = response.data.data
                this.scrollToEnd()
            })
        },

        postMessage() {

            toastr.options = {
                "closeButton": true,
            }

            this.await = true;

            let formData = new FormData();

            if(this.form.message != null) {
                formData.append('message', this.form.message);
            }

            $.each(this.form.attach_files, function(key, item) {
                formData.append(`attach_files[${key}]`, item)
            })

            axios.post(this.postUrl, formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(response => {
                    this.getMessages()
                    this.form = {}
                    this.scrollToEnd()
                    setTimeout(() => this.await = !this.await, 1000)
                }).catch(e => {
                    toastr.error(e.response.data.message);
                    setTimeout(() => this.await = !this.await, 1000)
                })
        },

        scrollToEnd: function () {
            let content = this.$refs.messagesContainer;
            content.scrollTop = content.scrollHeight
        },

        uploadFile(event) {
            this.form.attach_files = []

            for(let file of event.target.files){
                try {
                    this.form.attach_files.push(file);
                } catch {
                    console.log(`pushing files failed`);
                }
            }

            if(this.form.attach_files) {
                this.postMessage();
            }
        },
    }
}
</script>
<style>
.upload {

}
</style>
