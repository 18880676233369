<template>
    <div class="d-flex justify-content-end btn-group-table align-items-center">
        <button @click="showModalReject" class="btn btn-secondary mr-2">
            Отправить на доработку
        </button>
    </div>
</template>

<script>
import ModalShowRejectComponent from "../../../modal/Proposol/Tenant/ModalComponent";

export default {
    name: "ShowRejectComponent",
    props: ['rejectUrl', 'redirectUrl'],
    data () {
        return {
            user: null
        }
    },
    methods: {
        showModalReject() {
            this.$modal.show(ModalShowRejectComponent,
                {
                    rejectUrl: this.rejectUrl,
                    redirectUrl: this.redirectUrl
                },
                {
                    height: 'auto',
                    width: '400px'
                })
        },
    }
}
</script>
